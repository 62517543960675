.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.address-page {
  /*地址列表*/
  /*新增按钮*/
  /* 列表为空*/
}
.address-page .yd-list-donetip {
  font-size: 0.28rem;
  color: #999;
}
.address-page .address-list {
  width: 100%;
  overflow: hidden;
}
.address-page .address-item {
  width: 100%;
  background: #fff;
  padding: 0 0.24rem;
  margin-bottom: 0.2rem;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  /*是否默认*/
  /*删除按钮*/
}
.address-page .address-item[data-type="0"] {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.address-page .address-item[data-type="1"] {
  -webkit-transform: translate3d(-1.8rem, 0, 0);
          transform: translate3d(-1.8rem, 0, 0);
}
.address-page .address-item .address-box {
  min-height: 1.96rem;
  padding: 0.24rem 1.44rem 0.24rem 0;
  line-height: 0.44rem;
  font-size: 0.28rem;
  color: #333;
  background: white url(../../../assets/icon_more@3x.png) no-repeat 100% 50%;
  background-size: 0.16rem 0.26rem;
}
.address-page .address-item .address-box .person-info {
  margin-bottom: 0.24rem;
  font-size: 0.32rem;
  color: #333;
}
.address-page .address-item .address-box .person-info .mobile {
  margin-left: 0.24rem;
}
.address-page .address-item .setting-box {
  height: 0.84rem;
}
.address-page .address-item .setting-box .left-box {
  width: 50%;
}
.address-page .address-item .setting-box .left-box .select-item {
  width: 20px;
  height: 20px;
}
.address-page .address-item .setting-box .left-box .selected-item {
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
}
.address-page .address-item .setting-box .left-box span {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  margin-left: 0.24rem;
}
.address-page .address-item .setting-box .right-box {
  width: 1.8rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.28rem;
  color: var(--main-color);
  border: 0.02rem solid var(--main-color);
  border-radius: 0.48rem;
}
.address-page .address-item .delete {
  width: 1.8rem;
  height: 100%;
  background: #FF5252;
  font-size: 0.28rem;
  color: #fff;
  line-height: 2.82rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1.82rem;
  z-index: 3;
}
.address-page footer {
  width: 100%;
  height: 1.12rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  z-index: 3;
}
.address-page .empty-box {
  background: #fff;
  height: 100vh;
  font-size: 0.28rem;
  color: #999;
}
.address-page .empty-box img {
  width: 3.64rem;
  height: 2.58rem;
  margin-top: 1.76rem;
  margin-bottom: 0.74rem;
}
